.loginPageContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  height: 40vh;
  background-color: var(--background);
  text-align: center;
}
.splashEnter {
  /* height: 100vh; */
  /* overflow: hidden; */
}

.backgroundImage {
  background-size: cover;
  background-position: center;
  height: 30vh;
  width: 100vw;
  object-fit: cover;
  border-width: 0;
  margin-bottom: -1vh;
}
.welcomeLine {
  font-family: var(--cursive-font);
  text-align: center;
  width: 100%;
}
.dateLocation {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

@media screen and (min-width: 500px) {
  .topImage {
    display: none;
  }
  .backgroundImage {
    width: 100%;
    height: 100%;
  }
  .loginPageContent {
    width: 300px;
    position: absolute;
    width: 60vw;
    max-width: 400px;
    right: 3vw;
    top: 30vh;
  }
  .splashEnter {
    height: 100vh;
    overflow: hidden;
  }
  .languageBtn {
    position: absolute;
    right: 3vw;
    /* left: 0; */
    top: 0;
    width: 100px;
    max-width: 25%;
  }
}
