header h1 {
  color: var(--cta);
  margin-top: 1vh;
}

.inputField {
  width: 80%;
  padding: 0.5em 5%;
  margin: 0.5em 5%;
  font-size: 1.2em;
  border: 1px solid var(--cta);
}
.inputField:focus {
  border: 2px solid var(--primary);
}

.loginButton {
  background-color: var(--cta);
  height: 8vh;
  border-width: 0;
  width: 100vw;
  color: var(--background);
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginButton:disabled {
  background-color: var(--disabled);
}

@media screen and (min-width: 500px) {
}
