.greeting {
  background-color: var(--cta);
  color: var(--background);
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  z-index: 1;
}

.greeting .welcomeText {
  line-height: 1.7em;
  font-family: var(--cursive-font);
}

.text {
  font-size: 18px;
}

.bottomDashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--palette);
  background-position: center;
  background-size: cover;
  flex-grow: 1;
  position: relative;
}
.dashboard .bgImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  height: 100% !important;
  z-index: -1;
}

.weddingInfoContainer,
.receptionInfoContainer {
  width: 80vw;
}

.attendDecline {
  font-size: 20px;
  margin-bottom: 10px;
}

textarea {
  width: calc(100% - 6px);
  font-size: 20px;
  border: 1px solid var(--cta);
  border-radius: 2px;
}

input[type="range"] {
  width: 100%;
}

.receptionText {
  font-size: 20px;
}

.nameBox {
  border: 1px solid var(--cta);
  margin: 3px 0;
  border-radius: 3px;
  padding: 10px;
}
.dropdownRow {
  margin-bottom: 10px;
}

@media screen and (min-width: 500px) {
  .bottomDashboard {
    width: 60vw;
    max-width: 400px;
    margin-right: 3vw;
  }

  .dashboard {
    align-items: flex-end;
  }
  .weddingInfoContainer,
  .receptionInfoContainer {
    width: 100%;
  }
  .dropdownRow {
    margin-bottom: 10px;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .dropdownRow select {
    margin-left: 5%;
    width: 47.5%;
    height: 1.75em;
  }
  .dropdownRow div {
    width: 47.5%;
    text-align: right;
    line-height: 1em;
  }
}
