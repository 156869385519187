body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --cursive-font: "GreatVibes", "Lucinda handwriting", "allura", cursive;
  --medium-width: 500px;
  --large-width: 800px;
  --cta: #3d405b;
  --background: #f4f1de;
  --secondBackground: #eab69f;
  --primary: #8f5d5d;
  --secondary: #f2cc8f;
  /* --tertiary: #4352af; */
  --tertiary: #81b29a;
  --disabled: #8c8c8c;
  --error: #8f5d5d;
}

html {
}
a:visited {
  color: var(--tertiary);
}
a {
  color: var(--primary);
}

body {
  background-color: var(--background);
}
