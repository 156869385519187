.hamburgerMenu {
}

.hamburgerIcon {
  cursor: ponter;
  user-select: none;
  margin-right: 10px;
  color: var(--background);
}

.hamburgerMenuContainer {
  width: 100vw;
  position: absolute;
  right: 0;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--tertiary);
}
.hamburgerMenuContainer a {
  cursor: pointer;
  padding: 10px 0;
  user-select: none;
}
.hamburgerMenuContainer a:hover {
  background-color: rgba(0, 0, 0, 0.25);
}

@media screen and (min-width: 500px) {
  .hamburgerIcon {
    margin-right: 3vw;
  }
  .hamburgerMenuContainer {
    width: 30vw;
    max-width: 200px;
    margin-right: 0;
  }
}
