.rsvpButton {
  width: 100%;
  height: 8vh;
  max-height: 60px;
  border-width: 0;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  color: var(--background);
}

.rsvpButton.primary {
  background-color: var(--cta);
}
.rsvpButton.primary:hover {
  background-color: #575a75;
}
.rsvpButton.primary:active {
  background-color: #242742;
}

.rsvpButton.secondary {
  background-color: var(--primary);
}
.rsvpButton.secondary:hover {
  background-color: #a97777;
}
.rsvpButton.secondary:active {
  background-color: #764444;
}

.rsvpButton.cancel {
  background-color: var(--secondary);
}
.rsvpButton.cancel:hover {
  background-color: #ffe6a9;
}
.rsvpButton.cancel:active {
  background-color: #d9b376;
}

.rsvpButton:disabled {
  background-color: var(--disabled) !important;
}
