.questionPage {
  background-color: var(--background);
  width: 100%;
  padding: 0;
  align-items: flex-start;
  text-align: left;
}
.questionPageTitle {
  background-color: var(--primary);
  color: var(--background);
  padding: 10px;
}

.questionPage .question {
  font-weight: bold;
  color: var(--cta);
}
.questionPage .answer {
  margin-bottom: 10px;
}

.questions {
  padding: 10px;
}
